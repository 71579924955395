<template>
  <div>
    <b-table
      responsive
      show-empty
      id="my-table"
      class="custom-table-style custom-table-rapport-style custom-table-doublent"
      :items="type == 'TH' ? getDossierDoublentTh : getDossierDoublent"
      :fields="type == 'TH' ? fieldsTh : fields"
      sticky-header
      bordered
      hover
      head-variant="light"
      empty-text="Il n'y a aucun enregistrement à afficher"
    >
      <template v-slot:cell(numero_dossier)="data">
        {{ data.item.numero_dossier }}
      </template>
      <template v-slot:cell(semaine)="data">
        {{ data.item.semaine }}
      </template>
      <template v-slot:cell(depot)="data">
        {{ data.item.depot }}
      </template>
      <template v-slot:cell(nom1)="data">
        {{ data.item.nom1 }}{{ data.item.prenom1 }}
      </template>
      <template v-slot:cell(actions)="data">
        <font-awesome-icon
          icon="trash"
          class="icon-trash-component"
          @click.prevent="DeleteDoublent(data.item)"
        />
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    annee: { require: true },
    semaine: { require: false },
    depot: { require: false },
    month: { required: false },
    type: { require: true },
    change: { required: true }
  },
  data() {
    return {
      fields: [
        {
          key: 'actions',
          label: ''
        },
        {
          key: 'numero_dossier',
          label: 'Numéro dossier'
        },
        {
          key: 'semaine',
          label: 'Semaine'
        },
        {
          key: 'nom1',
          label: 'Nom & prénom'
        }
      ],
      fieldsTh: [
        {
          key: 'actions',
          label: ''
        },
        {
          key: 'numero_dossier',
          label: 'Numéro dossier'
        },
        {
          key: 'depot',
          label: 'Dépot'
        },
        {
          key: 'nom1',
          label: 'Nom & prénom'
        }
      ]
    };
  },
  methods: {
    ...mapActions([
      'fetchDoublentDossier',
      'fetchDoublentDossierTh',
      'deleteProjet',
      'deleteProjetTh'
    ]),
    DeleteDoublent(payload) {
      if (this.type == 'TH') {
        this.deleteProjetTh({ item: payload, type: this.type });
      } else {
        this.deleteProjet({ item: payload, type: this.type });
      }
    }
  },
  computed: {
    ...mapGetters(['getDossierDoublent', , 'getDossierDoublentTh'])
  },
  mounted() {
    if (this.type == 'TH') {
      if (this.annee != null && this.month != null) {
        this.fetchDoublentDossierTh({
          depot: this.depot,
          annee: this.annee,
          mois: this.month
        });
      }
    } else {
      if (this.semaine && this.semaine.value) {
        this.fetchDoublentDossier({
          semaine: 's' + this.semaine.value,
          type: this.type,
          annee: this.annee
        });
      }
    }
  },
  watch: {
    change: function() {
      if (this.type == 'TH') {
        if (this.month != null && this.annee != null) {
          this.fetchDoublentDossierTh({
            depot: this.depot,
            annee: this.annee,
            mois: this.month
          });
        }
      } else {
        this.fetchDoublentDossier({
          semaine: 's' + this.semaine.value,
          type: this.type,
          annee: this.annee
        });
      }
    }
  }
};
</script>

<style lang="scss">
.custom-table-doublent {
  max-height: calc(100vh - 200px);
}
</style>
